<template>
  <div class="settingContent" v-loading="contentLoading">
    <div>
      <el-form ref="formValidate" class="form" label-width="70px" :model="uiSetting">
        <el-form-item :label="$t('uiSetting.systemName')">
          <el-input style="width: 50%;" v-model="uiSetting.systemName" :placeholder="$t('common.pleaseEnter')" :maxlength="30"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="5">
            <div>
              <el-form-item :label="$t('uiSetting.logoLarge')">
                <el-upload :action="getAction" :on-success="handleSuccessLogoLarge" :file-list="logoFile" :limit="1"
                  :on-error="handlerError" :on-exceed="onExceed" :on-remove="handleRemoveLogoLarge"
                  :before-upload="beforeAvatarUpload" accept="image/*" list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="5">
            <div>
              <el-form-item :label="$t('uiSetting.logoSmall')">
                <el-upload :action="getAction" :on-success="handleSuccessLogoSmall" :file-list="logoSmallFile" :limit="1"
                  :on-error="handlerError" :on-exceed="onExceed" :on-remove="handleRemoveLogoSmall"
                  :before-upload="beforeAvatarUpload" accept="image/*" list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="5">
            <div>
              <el-form-item :label="$t('uiSetting.faviconIcon')">
                <el-upload :action="getAction" :on-success="handleSuccessFaviconIcon" :file-list="faviconIconFile"
                  :limit="1" :on-error="handlerError" :on-exceed="onExceed" :on-remove="handleRemoveFaviconIcon"
                  :before-upload="beforeAvatarUpload" accept="image/*" list-type="picture-card">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <!-- <el-form-item :label="$t('uiSetting.banner')">
          <el-upload :action="getAction" :on-success="handleSuccessBanner" :on-remove="handleRemoveBanner"
            :on-error="handlerError" :before-upload="beforeAvatarUpload" :file-list="bannerFiles" :limit="5"
            :on-exceed="onExceed" list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item :label="$t('uiSetting.appBanner')">
          <el-upload :action="getAction" :on-success="handleSuccessAppBanner" :on-remove="handleRemoveAppBanner"
            :on-error="handlerError" :before-upload="beforeAvatarUpload" :file-list="appBannerFiles" :limit="5"
            :on-exceed="onExceed" list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button class="saveBtn" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
            {{ $t("common.save") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      activeName: "first",
      contentLoading: false,
      submitLoading: false,
      saveDisabled: false,
      bannerFiles: [],
      appBannerFiles: [],
      uiSetting: {
        id: 0,
        systemName: "",
        logoLarge: "",
        logoSmall: "",
        banners: "",
        appBanners: "",
        companyId: 0,
        faviconIcon: "",
        companyName: "",
      },
    };
  },
  computed: {
    title() {
      return (this.uiSetting.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.uiSetting");
    },
    logoFile() {
      if (this.uiSetting.logoLarge) {
        return [{ url: this.getFullUrl(this.uiSetting.logoLarge) }];
      }
      return [];
    },
    logoSmallFile() {
      if (this.uiSetting.logoSmall) {
        return [{ url: this.getFullUrl(this.uiSetting.logoSmall) }];
      }
      return [];
    },
    faviconIconFile() {
      if (this.uiSetting.faviconIcon) {
        return [{ url: this.getFullUrl(this.uiSetting.faviconIcon) }];
      }
      return [];
    },
    getAction() {
      return window.config.SERVER_URL + "uiSettings/upload";
    },
  },
  mounted() {
    this.getCurrent();
  },
  methods: {
    getCurrent() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$axios.get("uiSettings/sysCurrent").then(res => {
        this.contentLoading = false;
        this.saveDisabled = false;
        this.uiSetting = res.data;
        if (this.uiSetting.banners) {
          for (const banner of JSON.parse(this.uiSetting.banners)) {
            this.bannerFiles.push({ url: this.getFullUrl(banner), path: banner });
          }
        }
        if (this.uiSetting.appBanners) {
          for (const banner of JSON.parse(this.uiSetting.appBanners)) {
            this.appBannerFiles.push({ url: this.getFullUrl(banner), path: banner });
          }
        }
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    beforeAvatarUpload(file) {
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      let isJPG = (fileType === "jpg" || fileType === "jpeg");
      let isPNG = fileType === "png";
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error(this.$t("uiSetting.tip.fileType"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("uiSetting.tip.isLt2M"));
      }
      return (isJPG || isPNG) && isLt2M;
    },
    handleSuccessLogoLarge(response) {
      this.uiSetting.logoLarge = response;
    },
    handleSuccessLogoSmall(response) {
      this.uiSetting.logoSmall = response;
    },
    handleSuccessFaviconIcon(response) {
      console.log(response);
      this.uiSetting.faviconIcon = response;
    },
    handlerError(err, file, fileList) {
      alert(err);
    },
    onExceed(files, fileList) {
      alert(this.$t("uiSetting.tip.pictures"));
    },
    handleSuccessBanner(response, file, fileList) {
      file.path = response;
      this.bannerFiles = fileList;
    },
    handleRemoveBanner(file, fileList) {
      this.bannerFiles = fileList;
    },
    handleSuccessAppBanner(response, file, fileList) {
      file.path = response;
      this.appBannerFiles = fileList;
    },
    handleRemoveAppBanner(file, fileList) {
      this.appBannerFiles = fileList;
    },
    handleRemoveLogoLarge(file, fileList) {
      this.uiSetting.logoLarge = "";
    },
    handleRemoveLogoSmall(file, fileList) {
      this.uiSetting.logoSmall = "";
    },
    handleRemoveFaviconIcon(file, fileList) {
      this.uiSetting.faviconIcon = "";
    },
    getFullUrl(path) {
      return window.config.SERVER_URL + path;
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let banners = [];
          for (const file of this.bannerFiles) {
            banners.push(file.path);
          }
          let appBanners = [];
          for (const file of this.appBannerFiles) {
            appBanners.push(file.path);
          }
          this.uiSetting.banners = JSON.stringify(banners);
          this.uiSetting.appBanners = JSON.stringify(appBanners);
          this.$api.save("uiSettings", this.uiSetting).then(res => {
            this.submitLoading = false;
            this.$emit("save-success", this.uiSetting.id === 0);
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.settingContent {
  position: absolute;
  top: 0;
  bottom: 32px;
  background-color: #fff;
  left: 16px;
  right: 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
  padding: 24px 44px;
}

.saveBtn {
  padding: 0 20px;
  height: 32px;
  border-radius: 8px;
  font-size: 14px;
  background-color: #0747FD;
  color: #fff;
  border: none;
}
</style>
